<template>
    <div style="padding-top: 3px; margin: 0 -30px;">

        <!-- Video Aside -->
        <AppAside v-if="!mobile" fixed style="margin-top: -73px">
            <videoAside :name="videoAside.name" :device="videoAside.camera"
                        :mobile="mobile" :bool="videoAside.bool" :sourceToken="videoAside.sourceToken"
                        :dataChannel="videoAside.dataChannel"/>
        </AppAside>

        <!-- Layout/Grid Presets/Buttons -->
        <div v-if="!videoAside.bool" class="d-flex flex-row justify-content-between w-100"
             style="margin: -25px 0 2px 2px; height: 20px;">

            <!-- Layouts -->
            <div class="d-flex flex-row" :class="mobile ? 'd-flex flex-grow-1' : ''">
                <b-input-group size="sm" style="font-size: 15px" :style="'width: ' + mobile ? '100%' : '0'"
                               :class="mobile ? 'd-flex' : ''">
                    <b-input-group style="font-size: 15px; height: 0; width: 200px" size="sm" v-if="!mobile">
                        <b-input-group-prepend class="input-group-sm" style="height: 20px">
                            <b-input-group-text style="line-height: 1; background-color: #2b2b2b">
                                Grid Presets
                            </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-select v-if="!mobile" v-model="gridPresets.current" :options="gridPresets.all"
                                       size="sm" style="margin-right: 3px; height: 20px; font-size: 9px"/>
                    </b-input-group>
                    <b-input-group style="font-size: 15px; height: 0; width: 53px" size="sm">
                        <b-input-group-prepend class="input-group-sm" style="height: 20px">
                            <b-input-group-text style="line-height: 1; background-color: #2b2b2b">
                                Layout
                            </b-input-group-text>
                        </b-input-group-prepend>
                        <div/>
                    </b-input-group>
                    <formSelectSearch :options="layouts.all.map(_ => { return {text: _.layoutName, value: _._id};})"
                                      v-model="layouts.layoutName" :mergeLeft="true" :userValue="true"
                                      :mergeRight="true" size="sm" @displayLayout="refreshLayout"
                                      :class="mobile ? 'flex-fill' : ''"
                                      :height="20" :width="mobile ? 1 : 0"/>
                    <b-input-group-append v-if="layouts.all.find(_ => _.layoutName === layouts.layoutName)">
                        <b-button variant="success" :disabled="layouts.layoutName === ''" size="sm" :title="layouts.showingLayout === true ? 'Refresh Layout' : 'Show Layout'"
                            style="height: 18px; transform: translateY(1px); line-height: .9" @click="refreshLayout()">
                            <div class="h-100 d-flex align-items-center" v-if="layouts.showingLayout">
                                <fa-icon :icon="['fas', 'refresh']"/>
                            </div>
                            <div class="h-100 d-flex align-items-center" v-else>
                                <fa-icon :icon="['fas', 'eye']"/>
                            </div>
                        </b-button>
                        <b-button variant="primary" :disabled="layouts.layoutName === ''" size="sm" title="Save Layout"
                                  style="height: 18px; transform: translateY(1px); line-height: .9" @click="saveLayout">
                            <div class="h-100 d-flex align-items-center">
                                <fa-icon :icon="['fas', 'download']"/>
                            </div>
                        </b-button>
                        <b-button variant="danger" :disabled="layouts.layoutName === ''" size="sm" title="Delete Layout"
                                  style="height: 18px; transform: translateY(1px); line-height: .9"
                                  @click="deleteLayout(layouts.all.find(_ => _.layoutName === layouts.layoutName)._id)">
                            <div class="h-100 d-flex align-items-center">
                                <fa-icon :icon="['fas', 'trash']"/>
                            </div>
                        </b-button>
                    </b-input-group-append>
                    <b-input-group-append v-else>
                        <b-button variant="success" :disabled="layouts.layoutName === ''" size="sm"
                                  style="height: 18px; transform: translateY(1px); line-height: .9" @click="saveLayout">
                            <span v-if="!mobile">Create Layout</span>
                            <span v-else>Create</span>
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
                <div v-if="!mobile" style="width: 0"/>
                <b-input-group-text v-if="layouts.all.find(_ => _.layoutName === layouts.layoutName)"
                                    size="sm"
                                    class="input-group-sm"
                                    style="font-size: 0.76562rem; height: 20px; line-height: 1; background-color: #2b2b2b; margin: 0 5px 0 5px">
                    <input type="checkbox" v-model="layouts.defaultLayout" @input="putDefaultLayout"/>
                    <div style="width: 5px"/>
                    <span v-if="!mobile">Make Default</span>
                    <span v-else>Default</span>
                </b-input-group-text>
            </div>

            <!-- Buttons -->
            <div class="d-flex flex-row justify-content-end align-items-center" id="expandable">
                <fa-icon v-if="!mobile" :icon="['fas', 'maximize']" style="margin: 0 6px 0 3px; cursor: pointer;" @click="toggleFullscreen(true)"/>
                <b-form-select v-if="!mobile" v-model="selectedQuality" :options="qualityOptions" @change="setQuality"
                               size="sm" style="margin-right: 3px; height: 20px; font-size: 9px"/>
                <div v-if="!mobile" style="width: 6px"/>
                <b-button variant="warning" :disabled="currentCameras.length < 1" size="sm" @click="clearCameras()"
                          style="height: 18px; line-height: .9; margin: 0 5px 3px 0; transform: translateY(1px)">
                    &nbsp;Clear&nbsp;
                </b-button>
                <div style="width: 6px"/>
            </div>

        </div>

        <!-- Mobile Grid -->
        <div v-show="mobile && !videoAside.bool">
            <div ref="vContainer" style="width: 100%; background-color: #191c1f"
                 class="d-flex flex-column w-auto">
                <div style="background-color: #0f0c4f; border: 1px solid #0f0c4f; width: 100vw"
                     :style="'height: ' + (((container.width / element.width) * element.height) + 25) + 'px'"
                     v-for="(stream, index) in currentCameras" :id="'grid-div-mobile-' + stream.ssDevice.getDeviceId() + stream.sourceToken + stream.dataChannel"
                     :key="stream.ssDevice.getDeviceId() + stream.sourceToken + stream.dataChannel" class="d-flex flex-column rounded w-100">
                    <div v-if="mobile" :id="'video-container-' + stream.ssDevice.getDeviceId() + stream.sourceToken + stream.dataChannel" class="flex-fill d-flex" style="position: relative; height: 100%; width: 100%">
                        <stream :device="stream.ssDevice" :sourceToken="stream.sourceToken" :dataChannel="stream.dataChannel" class="d-flex flex-fill"/>
                        <PlayerControls :device="stream.ssDevice" :isStreamOptions="videoAside.bool" class="flex-fill h-100 w-100" :sourceToken="stream.sourceToken" :dataChannel="stream.dataChannel"
                                        @openStreamOptions="openStreamOptions(stream, index, 'video-container-' +  stream.ssDevice.getDeviceId() + stream.sourceToken + stream.dataChannel)"
                                        @close="closeStream(stream)" @toggleDraggable="toggleDraggable" :playback="playback"
                                        containerIDPrefix="video-container-" style="position: absolute" @closeStreamOptions="closeStreamOptions()"/>
                    </div>
                </div>
            </div>
        </div>

        <!-- Desktop Grid -->
        <div v-show="mobile === false && videoAside.bool === false">
            <div class="d-flex flex-row justify-content-center align-items-center align-content-stretch"
                 :style="videoAside.bool && !mobile ? '' : fullscreen ? 'height: 100vh; width: 100vw;' : 'height: calc(100vh - 175px); width: 100%'"
                 @keydown.esc="toggleFullscreen(false)">
                <div ref="vContainer" id="container" v-if="!mobile" style="background-color: #191C1F; overflow: hidden"
                     class="d-flex flex-row flex-wrap align-content-center justify-content-center align-items-center align-self-stretch flex-grow-1 gridBackground position-relative"
                     :style="fullscreen ? 'width: 100vw; height 100vh' : 'height: calc(100vh - 175px); width: 100%'">
                    <div v-if="!mobile" class="position-absolute w-100 h-100 d-flex justify-content-center align-items-center">
                        <img src="/img/brand/mtoplogogreyShadow.png" alt="logo"
                             style="opacity: .1; max-height: 12%; max-width: 95%"/>
                    </div>
                    <GridPresetView v-if="gridPresets.current !== 'Infinity Grid'" :style="vGrid.elementStyle" @closeStreamOptions="closeStreamOptions"
                                    :currentGridPreset="gridPresets.current" :currentCameras="currentCameras" :playback="playback"
                                    :isStreamOptions="videoAside.bool" @openStreamOptions="(data) => openStreamOptions(data.stream, data.index, data.containerId)"
                                    @updateGridStreams="updateGridStreams" :layout="gridPresets.currentLayout" :supportMessageReceived="supportMessageReceived"/>
                    <draggable v-else :disabled="!streamDraggable" :list="currentCameras" ghost-class="ghost" class="list-group d-flex flex-row flex-wrap align-content-center justify-content-center align-items-center align-self-stretch w-100">
                        <!-- Currently displayed cameras -->
                        <div style="background-color: #0f0c4f; border: 1px solid #0f0c4f; position: relative; width: 100%"
                             :style="vGrid.elementStyle" :id="'grid-div-' + stream.ssDevice.getDeviceId() + stream.sourceToken + stream.dataChannel"
                             v-for="(stream, index) in currentCameras"
                             @mouseover="hovering = stream.ssDevice.getDeviceId()" @mouseout="hovering = ''"
                             :key="stream.ssDevice.getDeviceId() + stream.sourceToken + stream.dataChannel" class="d-flex flex-column rounded overflow-hidden">
                            <div v-if="!mobile" :id="'video-container-' + stream.ssDevice.getDeviceId() + stream.sourceToken + stream.dataChannel" class="flex-fill d-flex noHighlight" style="position: relative; height: 100%; width: 100%">
                                <stream :device="stream.ssDevice" :sourceToken="stream.sourceToken" :dataChannel="stream.dataChannel" :quality="stream.quality" class="d-flex flex-fill"
                                        :id="stream.ssDevice.getDeviceId() + stream.sourceToken + stream.dataChannel"/>
                                <PlayerControls :device="stream.ssDevice" :isStreamOptions="videoAside.bool" class="flex-fill h-100 w-100" :sourceToken="stream.sourceToken" :dataChannel="stream.dataChannel"
                                                :quality="stream.quality" @openStreamOptions="openStreamOptions(stream, index, 'video-container-' +  stream.ssDevice.getDeviceId() + stream.sourceToken + stream.dataChannel)"
                                                @close="closeStream(stream)" @toggleDraggable="toggleDraggable" :playback="playback" :showStreamOptions="supportMessageReceived[stream.ssDevice.getTenantId()] !== undefined"
                                                containerIDPrefix="video-container-" style="position: absolute; z-index: 2" @closeStreamOptions="closeStreamOptions()" @fullscreenChanged="setFullscreenValue"/>
                            </div>
                        </div>
                    </draggable>
                </div>
            </div>
        </div>

        <!-- Stream Settings -->
        <div v-if="!mobile" v-show="videoAside.bool" :id="'stream_settings_container_' + videoAside.playerId"
             :class="!videoAside.bool ? '' : 'd-flex flex-column align-content-stretch'" style="margin-top: -28px;"
             :style="!videoAside.bool ? 'display: none' : fullscreen ? 'height: 100vh; width: 100vw;' : 'height: calc(100vh - 150px); width: 100%'">
            <div id="streamSettings" class="flex-grow-1 flex-shrink-1 d-flex" style="min-height: 0"/>
            <SmartSuiteTimeline v-if="videoAside.camera !== null" :sourceToken="videoAside.sourceToken"
                                :device="videoAside.camera" :dataChannel="videoAside.dataChannel"/>
        </div>

        <!-- Stream Settings Mobile -->
        <div v-else v-show="videoAside.bool && videoAside.index"
             style="width: auto; overflow-y: hidden; margin-top: -25px" class="d-flex flex-column"
             :style="!videoAside.bool && !mobile ? 'height: 0px; overflow-y: hidden' : 'height: calc(100vh - 155px); width: 100%;'">
            <div id="mobileStreamSettings" style="width: 100%; height: calc(100vw * 9 / 16)" class="d-flex flex-column"/>
            <div style="width: 100%; overflow-y: auto; height: calc(100% - (100vw * 9 / 16))">
                <videoAside :name="videoAside.name" :device="videoAside.camera"
                            :mobile="mobile" :bool="videoAside.bool"  :sourceToken="videoAside.sourceToken" :dataChannel="videoAside.dataChannel"/>
            </div>
        </div>

    </div>
</template>

<script>
import Vue from 'vue';
import MaskedInput from 'vue-text-mask';
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import {isMobile} from 'mobile-device-detect';
import formSelectSearch from "@/components/formSelectSearch";
import videoAside from "./videoAside";
import stream from "@/views/video_wall/stream_components/stream.vue";
import {Aside as AppAside} from '../template_files/navbarJazz';
import draggable from "vuedraggable";
import eleQueries from "css-element-queries";
import SmartSuiteTimeline from "@/views/video_wall/stream_components/SmartSuiteTimeline";
import deviceStore from "@/store/deviceStore";
import AudioDropdown from "@/views/video_wall/stream_components/AudioDropdown";
import PlayerControls from "@/views/video_wall/stream_components/PlayerControls";
import GridPresetView from "@/views/video_wall/GridPresetView.vue";
import GridPresets from "@/views/video_wall/GridPresets";
import {v4 as uuidv4} from 'uuid';
import PubSub from "pubsub-js";
import gammaStore from "@/store/gammaStore";
import SmartSuiteDevice from "@/shared/smartsuite_services/smartsuite_device";
import SmartSuiteLivestream from "@/shared/smartsuite_services/smartsuite_livestream";
import {debounce} from 'lodash';

export default {
    name: "VideoWall",
    components: {
        GridPresetView,
        PlayerControls,
        AudioDropdown,
        SmartSuiteTimeline,
        eleQueries,
        draggable,
        datePicker,
        MaskedInput,
        formSelectSearch,
        videoAside,
        AppAside,
        stream,
    },

    data: () => {
        return {
            gridPresets: {
                current: 'Infinity Grid',
                all: ['Infinity Grid', ...Object.keys(GridPresets)],
                gridStreams: {},
                currentLayout: null,
            },
            layouts: {
                all: null,
                defaultLayout: false,
                layoutName: "",
                showingLayout: false,
                atCreated: {
                    tenantLength: null,
                    tenantsDone: 0,
                }
            },
            playback: false,
            hovering: "",
            mobile: isMobile,
            vGrid: {
                elementStyle: '',
                rows: 1,
                cols: 1,
            },
            container: {
                width: 0,
                height: 0,
            },
            element: {
                width: 16,
                height: 9
            },
            fullscreen: false,
            videoAside: {
                bool: false,
                name: '',
                camera: null,
                sourceToken: null,
                dataChannel: null,
                index: -1,
            },
            selectedQuality: deviceStore.getters.getQuality,
            qualityOptions: [
                {value: 0, text: 'Low'},
                {value: 1, text: 'Medium'},
                {value: 2, text: 'High'}
            ],
            streamDraggable: true,
            livehubConnections: {},
            currentLayout: {},
            supportMessageReceived: {},
            pubsubs: [],
            isSupportMessageReceivedForcedReload: false,
            pageRefreshTimeout: null
        }
    },

    async created() {
        document.addEventListener('fullscreenchange', this.handleFullScreenChange);
        document.addEventListener('keydown', this.handleKeyDown);
        window.addEventListener('mousemove', this.resetPageRefreshInterval);
        window.addEventListener('click', this.resetPageRefreshInterval);
        this.layouts.all = JSON.parse(localStorage.getItem('layouts')) || [];
        this.pubsubs.push(PubSub.subscribe('openLivestreamSupport', async (msg, data) => {
            for (const device of this.currentCameras) {
                let edgeDevice = device.ssDevice.getEdgeDevice();
                let tenantId = device.ssDevice.getTenantId().toString();
                //if the device has the same tenant and edge service
                if (data.tenantId === tenantId && data.edgeDevice === edgeDevice) {
                    //if there is no livehub connection or if it is closed for that tenant
                    if (this.livehubConnections[data.tenantId] === undefined || !this.livehubConnections[data.tenantId].isConnected()) {
                        this.livehubConnections[data.tenantId] = new SmartSuiteLivestream(null, data.tenantId);
                        await this.livehubConnections[data.tenantId].connect();
                        await this.livehubConnections[data.tenantId].setSupportMessageListener(async message => {
                            if (await deviceStore.dispatch('checkForEdgeListener', {tenantId: data.tenantId}) === false) {
                                message = Object.assign(message, {tenantId: data.tenantId});
                                deviceStore.dispatch('changeDeviceInfo', message);
                                Vue.set(this.supportMessageReceived, data.tenantId, true);
                            }
                        });
                    }
                    this.livehubConnections[data.tenantId].addSourceListener(device.ssDevice.getDeviceId() + device.sourceToken);
                }
            }
        }));
        this.pubsubs.push(PubSub.subscribe('closeLivestreamSupport', async (msg, data) => {
            for (const device of this.currentCameras) {
                let edgeDevice = device.ssDevice.getEdgeDevice();
                let tenantId = device.ssDevice.getTenantId().toString();
                //if tenant and edge match and the livehub is open and connected
                if (data.tenantId === tenantId && data.edgeDevice === edgeDevice && this.livehubConnections[data.tenantId] !== undefined && this.livehubConnections[data.tenantId].isConnected()) {
                    this.livehubConnections[data.tenantId].removeSourceListener(device.ssDevice.getDeviceId() + device.sourceToken);
                }
            }
        }));
        window.addEventListener('beforeunload', this.storeSessionInfo);
        this.resetPageRefreshInterval()
    },

    mounted() {
        new eleQueries.ResizeSensor(this.$refs["vContainer"], () => {
            this.rerenderStreams();
        });
        this.container.width = this.fullscreen ? window.screen.width : this.$refs.vContainer.clientWidth;
        this.container.height = this.fullscreen ? window.screen.height : this.$refs.vContainer.clientHeight;
        this.layouts.all.forEach((layout) => {
            if (layout.defaultLayout) {
                this.layouts.showingLayout = true;
                this.layouts.defaultLayout = true;
                this.layouts.layoutName = layout.layoutName;
            }
        });
        if (JSON.parse(sessionStorage.getItem('isForcedReload')) === true) {
            this.rebuildLayout();
        } else {
            this.refreshLayout();
        }
        deviceStore.dispatch('updateCurGridPreset', this.gridPresets.current);
    },

    beforeDestroy() {
        deviceStore.dispatch('clearStreamOptions');
        deviceStore.dispatch('clearActiveCameras');
        this.pubsubs.forEach(sub => {
            PubSub.unsubscribe(sub);
        });
        document.removeEventListener('fullscreenchange', this.handleFullScreenChange);
        document.removeEventListener('keydown', this.handleKeyDown);
        clearTimeout(this.pageRefreshTimeout);
    },

    methods: {
        resetPageRefreshInterval: debounce(function () {
            if (this.pageRefreshTimeout) {
                clearTimeout(this.pageRefreshTimeout);
            }
            this.pageRefreshTimeout = setTimeout(() => {
                sessionStorage.setItem('isForcedReload', true);
                window.location.reload();
            }, 4*60*60*1000);   //refresh every 4 hours
        }, 60*1000),
        async rebuildLayout() {
            sessionStorage.setItem('isForcedReload', false);
            await this.refreshLayout(true);
        },
        storeSessionInfo() {
            this.saveLayout(true);
            sessionStorage.setItem('isFullscreen', this.fullscreen);
            sessionStorage.setItem('videoAside', JSON.stringify({
                sourceId: this.videoAside.playerId,
                dataChannel: this.videoAside.dataChannel,
                sourceToken: this.videoAside.sourceToken,
                name: this.videoAside.name,
                bool: this.videoAside.bool,
                index: this.videoAside.index,
                id: this.videoAside.camera !== null ? this.videoAside.camera.getDeviceId() : null
            }));
        },
        handleFullScreenChange() {
            this.rerenderStreams();
            if (!document.fullscreenElement) {
                this.toggleFullscreen(false);
            }
        },
        handleKeyDown(e) {
            if (e.key === "Escape" && this.videoAside.bool ) {
                this.closeStreamOptions();
            }
        },
        updateGridStreams(gridStreams) {
            this.gridPresets.gridStreams = gridStreams;
        },
        toggleDraggable(bool) {
            this.streamDraggable = bool;
        },
        setQuality() {
            deviceStore.commit('setQuality', this.selectedQuality);
        },
        async clearCameras() {
            await deviceStore.dispatch('clearActiveCameras');
        },
        async openStreamOptions(stream, index, containerId) {
            await this.setVideoAside(stream, index, containerId);
            this.rerenderStreams();
            this.resizeStreamCanvas();
        },
        async closeStreamOptions() {
            await this.unsetVideoAside();
            this.rerenderStreams();
            this.resizeStreamCanvas();
        },
        async setVideoAside(stream, index, containerId) {
            await deviceStore.dispatch('updateStreamOptions', {
                bool: true,
                device: stream.ssDevice,
                dataChannel: stream.dataChannel,
                sourceToken: stream.sourceToken,
            });
            this.videoAside = Object.assign(this.videoAside, {
                bool: true,
                name: stream.ssDevice.getDeviceName(),
                camera: stream.ssDevice,
                sourceToken: stream.sourceToken,
                dataChannel: stream.dataChannel,
                playerId: stream.ssDevice.getDeviceId() + stream.sourceToken + stream.dataChannel,
                index: index
            });
            //add the single stream to view
            document.getElementById((this.mobile ? 'mobileS' : 's') + 'treamSettings').appendChild(document.getElementById(containerId));
        },
        async unsetVideoAside() {
            document.getElementById('grid-div-' + (this.mobile ? 'mobile-' : '') + this.videoAside.playerId).appendChild(document.getElementById('video-container-' + this.videoAside.playerId));
            this.videoAside = Object.assign(this.videoAside, {
                'camera.clickPTZ': false,
                bool: false,
                name: '',
                camera: null,
                sourceToken: null,
                dataChannel: null,
                playerId: null
            });
            deviceStore.dispatch('clearStreamOptions');
        },
        setFullscreenValue(bool) {
            //this method is simply to mirror what fullscreen's value should be when set from PlayerControls.vue
            this.fullscreen = bool;
        },
        toggleFullscreen(bool) {
            if (bool === true) {
                if (!this.fullscreen) {
                    this.fullscreen = true;
                    this.openFullscreen();
                }
            } else if (bool === false) {
                if (this.fullscreen) {
                    this.fullscreen = false;
                }
            }
        },
        resizeStreamCanvas() {
            this.currentCameras.forEach(camera => {
                try {
                    camera.ssDevice.resetVideoSize(camera.ssDevice.getDeviceId() + camera.sourceToken + camera.dataChannel, true, this.gridPresets.current !== 'Infinity Grid');
                } catch (e) {}
            })
        },
        openFullscreen() {
            if (!isMobile && this.$refs.vContainer) {
                let element = this.$refs.vContainer;
                if (element.requestFullscreen) {
                    element.requestFullscreen();
                } else if (element.mozRequestFullScreen) {
                    element.mozRequestFullScreen();
                } else if (element.webkitRequestFullscreen) {
                    element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
                } else if (element.msRequestFullscreen) {
                    element.msRequestFullscreen();
                }
            }
        },
        rerenderStreams() {
            if (!this.mobile && !this.videoAside.bool && this.$refs.vContainer) {
                this.container.width = this.fullscreen ? window.screen.width : this.$refs.vContainer.clientWidth;
                this.container.height = this.fullscreen ? window.screen.height : this.$refs.vContainer.clientHeight;
                let cHeight = this.container.height;
                let cWidth = this.container.width;
                let eHeight = this.element.height;
                let eWidth = this.element.width;
                let result = {
                    elementStyle: 'height:' + (cHeight / eHeight < cWidth / eWidth ? cHeight : ((cWidth / eWidth) * eHeight))
                        + 'px; width: ' + (cHeight / eHeight < cWidth / eWidth ? ((cHeight  / eHeight) * eWidth) : cWidth) + 'px;',
                    cols: 1,
                    rows: 1,
                };
                if (this.currentCameras.length > 1 && this.gridPresets.current === 'Infinity Grid') {
                    for (let i = 1; i <= this.currentCameras.length; i++) {
                        this.getRowCol(result.rows, result.cols, i, result.elementStyle, (vGrid) => {
                            result = vGrid;
                            if (i === this.currentCameras.length) {
                                this.vGrid = Object.assign({}, vGrid);
                            }
                        });
                    }
                } else {
                    this.vGrid = Object.assign({}, result);
                }
            }
        },
        getRowCol(rows, cols, length, style, callback) {
            let cHeight = this.container.height;
            let cWidth = this.container.width;
            let eHeight = this.element.height;
            let eWidth = this.element.width;
            let isByWidth = {bool: null, unit: null};
            if ((rows * cols) < length) {  // if (need to add row/col)
                if (this.addCol(rows, cols, cWidth, cHeight, eWidth, eHeight, isByWidth)) {  // if (should add col)
                    cols++;  // add col
                    while (((rows - 1) * cols) >= length) {  // if (can get rid of a row)
                        rows--;  // delete row
                    }
                } else {
                    rows++;  // add row
                    while ((rows * (cols - 1)) >= length) {  // if (can get rid of a col)
                        cols--;  // delete col
                    }
                }
                let nWidth = Math.floor(isByWidth.unit * eWidth) * 100 / cWidth;
                callback({
                    elementStyle: 'width: ' + nWidth + '%; aspect-ratio: 16/9;',
                    cols: cols,
                    rows: rows
                });
            } else {  // if (don't need to add row/col)
                callback({
                    elementStyle: style,
                    cols: cols,
                    rows: rows
                });
            }
        },
        addCol(rows, cols, cWidth, cHeight, eWidth, eHeight, isByWidth) {

            // Adding row calculations
            let rowByWidth = (cWidth / cols) / eWidth;
            let rowByHeight = (cHeight / (rows + 1)) / eHeight;
            let addRowUnitSize = Math.min(rowByWidth, rowByHeight);

            // Adding col calculations
            let colByWidth = (cWidth / (cols + 1)) / eWidth;
            let colByHeight = (cHeight / rows) / eHeight;
            let addColUnitSize = Math.min(colByWidth, colByHeight);

            // Comparing adding col to adding row
            let result = addRowUnitSize < addColUnitSize;
            if (result) {
                isByWidth.bool = colByWidth > colByHeight;
                isByWidth.unit = addColUnitSize;
            } else {
                isByWidth.bool = rowByWidth > rowByHeight;
                isByWidth.unit = addRowUnitSize;
            }
            return result;
        },
        async saveLayout(forcedReload) {
            let index = this.layouts.all.findIndex(_ => _.layoutName === this.layouts.layoutName);
            let layout = {
                devices: [],
                layoutName: this.layouts.layoutName,
                gridPreset: this.gridPresets.current,
                defaultLayout: ~index ? this.layouts.all[index].defaultLayout : false,
                _id: ~index ? this.layouts.all[index]._id : uuidv4(),
                tenants: {},
            };
            let temp = this.gridPresets.current !== 'Infinity Grid' ? Object.entries(this.gridPresets.gridStreams) : this.currentCameras;
            temp.forEach((device, index) => {
                let key = Array.isArray(device) ? device[0] : index;
                let value = Array.isArray(device) ? device[1] : device;
                if (value) {
                    layout.devices.push({
                        _id: value.ssDevice.getDeviceId(),
                        deviceSerial: value.ssDevice.getDeviceId(),
                        order: key,
                        dataChannel: value.dataChannel,
                        sourceToken: value.sourceToken,
                        streams: [value.ssDevice.findStream(value.dataChannel)],
                        tenantId: value.ssDevice.getTenantId(),
                        friendlyName: value.ssDevice.getDeviceName(),
                        hubName: value.ssDevice.getHubName(),
                        commandChannel: value.ssDevice.getCommandChannel(),
                        storageServer: value.ssDevice.getStorageServer(),
                        multisensor: value.ssDevice.getMultiSensor(),
                        model: value.ssDevice.getDeviceModel()
                    });
                    if (!layout.tenants[value.ssDevice.getTenantId()]) {
                        layout.tenants[value.ssDevice.getTenantId()] = {
                            _id: value.ssDevice.getTenantId()
                        };
                    }
                }
            });
            layout.tenants = Object.values(layout.tenants);
            if (~index) {  // Update Layout
                this.layouts.all.splice(index, 1, layout);
            } else {  // New Layout
                this.layouts.all.push(layout);
            }
            if (forcedReload === true) {
                sessionStorage.setItem('layout', JSON.stringify(layout));
            } else {
                localStorage.setItem("layouts", JSON.stringify(this.layouts.all));
            }
            this.layouts.showingLayout = true;
        },
        async showLayout(layout) {
            await this.clearCameras();
            //we still need all livestream hubs open before we connect
            layout.devices.forEach((device, index) => {
                deviceStore.dispatch('getDevice', {
                    tenantId: device.tenantId,
                    deviceId: device._id
                }).then(ssDevice => {
                    deviceStore.dispatch('addActiveCamera', {
                        index: index,
                        ssDevice: ssDevice !== null ? ssDevice : new SmartSuiteDevice(device),
                        dataChannel: device.dataChannel,
                        sourceToken: device.sourceToken
                    });
                });
            });
            if (this.gridPresets.current !== 'Infinity Grid') {
                this.gridPresets.currentLayout = Object.assign({}, layout);
            }
        },
        deleteLayout() {
            let layoutIndex = this.layouts.all.findIndex(_ => _.layoutName === this.layouts.layoutName);
            if (~layoutIndex) {
                this.layouts.all.splice(layoutIndex, 1);
                this.layouts.layoutName = '';
                localStorage.setItem("layouts", JSON.stringify(this.layouts.all));
            } else {
                console.error("Error: Unable to locate layout");
            }
        },
        closeStream(data) {
            deviceStore.dispatch('removeActiveCamera', data);
        },
        putDefaultLayout() {
            let layouts = this.layouts.all.reduce((newLayouts, layout, index) => {
                if (layout.layoutName === this.layouts.layoutName) {
                    layout.defaultLayout = !layout.defaultLayout;
                } else if (layout.defaultLayout) {
                    layout.defaultLayout = false;
                }
                Vue.set(newLayouts, index, layout);
                return newLayouts;
            }, []);
            this.layouts.all = layouts;
            localStorage.setItem("layouts", JSON.stringify(layouts));

        },
        updateSavedLayoutNames(message) {
            let layoutIndex = this.layouts.all.findIndex(layout => layout.layoutName === this.currentLayout.layoutName);
            if (layoutIndex !== -1) {
                let deviceIndex = this.layouts.all[layoutIndex].devices.findIndex(device => device.sourceToken === message.sourceToken && device.deviceSerial === message.deviceSerial)
                if (deviceIndex !== -1) {
                    this.layouts.all[layoutIndex].devices[deviceIndex].friendlyName = message.friendlyName;
                    localStorage.setItem("layouts", JSON.stringify(this.layouts.all));
                }
            }
        },
        async refreshLayout(forcedReload) {
            let defaultLayout;
            let videoAside = JSON.parse(sessionStorage.getItem('videoAside'));
            if (forcedReload === true) {
                defaultLayout = JSON.parse(sessionStorage.getItem('layout'));
            } else {
                defaultLayout = this.layouts.all.find(_ => _.layoutName === this.layouts.layoutName);
            }
            if (this.currentLayout !== undefined && Object.keys(this.currentLayout).length !== 0) {
                for (let j = 0; j < this.currentLayout.devices.length; j++) {
                    this.livehubConnections[this.currentLayout.devices[j].tenantId].removeSourceListener(this.currentLayout.devices[j]._id + this.currentLayout.devices[j].sourceToken);
                }
            }
            this.currentLayout = defaultLayout;
            if (defaultLayout) {
                //set up a livestream support listener for each device to receive updates
                await Promise.all(defaultLayout.tenants.map(async (tenant) => {
                    Vue.delete(this.supportMessageReceived, tenant._id);
                    this.livehubConnections[tenant._id] = await new SmartSuiteLivestream(null, tenant._id);
                    await this.livehubConnections[tenant._id].connect();
                    await this.livehubConnections[tenant._id].setSupportMessageListener(async message => {
                        if (await deviceStore.dispatch('checkForEdgeListener', {tenantId: tenant._id}) === false) {
                            message = Object.assign(message, {tenantId: tenant._id});
                            deviceStore.dispatch('changeDeviceInfo', message);
                            this.updateSavedLayoutNames(message);
                            Vue.set(this.supportMessageReceived, tenant._id, true);
                            if (forcedReload && message.deviceSerial === videoAside.id && this.isSupportMessageReceivedForcedReload === false) {
                                this.isSupportMessageReceivedForcedReload = true;
                                await this.openStreamOptions(this.currentCameras[videoAside.index], videoAside.index, `video-container-${videoAside.sourceId}`);
                            }
                        } else {
                            gammaStore.dispatch('closeLiveStreamhubListeners', tenant._id);
                        }
                    });
                }));

                //add each device to the device store
                for (let i = 0; i < defaultLayout.devices.length; i++) {
                        deviceStore.dispatch('getDevice', {
                            tenantId: defaultLayout.devices[i].tenantId,
                            deviceId: defaultLayout.devices[i]._id
                        }).then(async ssDevice => {
                            if (ssDevice === null) {
                                deviceStore.dispatch('addSmartSuiteDevice', {
                                    device: new SmartSuiteDevice(defaultLayout.devices[i]),
                                    tenantId: new SmartSuiteDevice(defaultLayout.devices[i]).getTenantId()
                                });
                            } else if (ssDevice.getSourceToken !== defaultLayout.devices[i].sourceToken) {
                                await deviceStore.dispatch('changeDeviceInfo', defaultLayout.devices[i]);
                            }
                        });
                    if (await deviceStore.dispatch('checkForEdgeListener', {
                        tenantId: defaultLayout.devices[i].tenantId,
                        edgeDevice: defaultLayout.devices[i].hubName
                    }) === false) {
                        this.livehubConnections[defaultLayout.devices[i].tenantId].addSourceListener(defaultLayout.devices[i]._id + defaultLayout.devices[i].sourceToken);
                    }
                    if (i === defaultLayout.devices.length - 1) {
                        this.layouts.showingLayout = true;
                        if (this.gridPresets.current !== defaultLayout.gridPreset) {
                            Vue.set(this.gridPresets, 'current', defaultLayout.gridPreset);
                        }
                        this.layouts.defaultLayout = defaultLayout.defaultLayout;
                        await this.showLayout(defaultLayout);
                    }
                }
            }
        },
    },

    watch: {
        'gridPresets.current'() {
            deviceStore.dispatch('updateCurGridPreset', this.gridPresets.current);
            this.clearCameras();
        },
        async currentCameras() {
            this.rerenderStreams();
            this.resizeStreamCanvas();
        },
        'layouts.layoutName'() {
            let defaultL = this.layouts.all.find(_ => _.layoutName === this.layouts.layoutName);
            if (!defaultL) {
                this.layouts.showingLayout = false;
                this.layouts.defaultLayout = false;
            } else {
                this.layouts.defaultLayout = defaultL.defaultLayout;
            }
        }
    },

    computed: {
        currentCameras() {
            let currentCameras = deviceStore.getters.getCurrentCameras;
            if (this.videoAside.bool && !currentCameras.some(_ => {
                    return (
                        this.videoAside.camera.getDeviceId() === _.ssDevice.getDeviceId()
                        && this.videoAside.sourceToken === _.sourceToken
                    );
                })) {
                this.closeStreamOptions();
            }
            return currentCameras;
        }
    }
}
</script>

<style scoped>
    .gridBackground {
        background: radial-gradient(#1a1a1a, #0e0e0e 70%);
    }
    .ghost {
        opacity: 0.5;
        display: none;
    }
    .noHighlight {
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
    }
</style>

<style>
    #container:-webkit-full-screen,
    #container:-moz-full-screen,
    #container:-ms-fullscreen,
    #container:fullscreen {
        width: 100vw;
        height: 100vh;
    }
    .break {
        flex-basis: 100%;
        height: 0;
        background-color: red;
    }
    .rounded-edges {
        border-bottom-right-radius: 2px !important;
        border-top-right-radius: 2px !important;
    }
    #right-curve {
        position: relative;
        width: 7px;
        height: 7px;
        content: " ";
        border-bottom-left-radius: 7px;
        transform: rotate(180deg);
        transform: scaleY(-1);
        box-shadow: -2px 2px 0 #0f0c4f;
    }
    #left-curve {
        position: relative;
        width: 7px;
        height: 7px;
        content: " ";
        border-bottom-left-radius: 7px;
        transform: rotate(180deg);
        box-shadow: -2px 2px 0 #0f0c4f;
    }
</style>
